import { BaseEntity } from "@/entities/base_entity";

export enum NeedKind {
	CLINICAL = "CLINICAL",
	SERVICE = "SERVICE",
}

export const needKindTranslated: Record<NeedKind, string> = {
	[NeedKind.CLINICAL]: "Clínica",
	[NeedKind.SERVICE]: "Serviço",
};

export function translateNeedKind(status?: NeedKind): string {
	if (!status) return "";
	return needKindTranslated[status];
}
export class INeed extends BaseEntity<INeed> {
	name: string;
	kind: NeedKind;
	synonyms?: string[];
}
