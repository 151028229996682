var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('entity-crud',_vm._b({},'entity-crud',{
			model: _vm.model,
			title: _vm.title,
			exportColumns: _vm.exportColumns,
			tableColumns: _vm.tableColumns,
			formColumns: _vm.formColumns,
			kind: _vm.kind,
			formStyleVariant: _vm.formStyleVariant,
			titleKind: _vm.titleKind,
			onRemoveEntity: _vm.onRemoveEntity,
			shouldGoToPreviousPageAfterRemove: _vm.shouldGoToPreviousPageAfterRemove,
			shouldShowExportButton: _vm.shouldShowExportButton,
		},false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }