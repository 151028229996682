





















import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import EntityForm from "@/views/entity/EntityForm.vue";
import needModel from "@/api/need.model";
import { translateNeedKind } from "@/interfaces/need";

@Component({
	components: { EntityCrud, EntityForm },
})
export default class Needs extends Vue {
	model: any = needModel;
	title = "Necessidades";
	kind = "tableAndForm";
	formStyleVariant = "secondary";
	titleKind = "secondary";
	shouldShowExportButton = true;

	shouldGoToPreviousPageAfterRemove = false;
	onRemoveEntity() {
		this.$root.$emit("refreshTable");
	}

	exportColumns = [
		{ key: "id", name: "Id" },
		{ key: "name", name: "Nome" },
		{ key: "kind", name: "Categoria", style: "bold" },
		{ key: "tussCode", name: "TUSS" },
		{ key: "synonyms", name: "Sinônimos" },
	];

	tableColumns = [
		{ key: "name", style: "bold" },
		{ key: "kind", style: "bold" },
	];

	formColumns = [
		{ key: "name", isReadOnly: false, required: true },
		{ key: "kind", inputKind: "selectButtons", onTransform: translateNeedKind, required: true },
		{ key: "tussCode" },
		{ key: "synonyms" },
	];
}
